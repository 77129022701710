body {
  font: 16px "avenir", Arial, sans-serif;
  background-color: #decdda;
}

.page {
  margin: 40px;
  padding: 30px;
}

header {
  font: 18px "avenir", sans-serif;
}

.homehead {
  margin: 30px;
  display: flex;
  justify-content: center;
}

.container {
  display: grid;
  gap: 10px;
  justify-content: center;

  grid-template-columns: 800px;
  grid-template-rows: 1fr;
}

.home-container {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid #b33f62; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.12em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #b33f62;
  }
}

.box {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.list {
  width: 400px;
}
.card {
  background-color: white;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.list-title {
  padding: 5px;
  border: none;
  height: 20px;
}

.item-container {
  padding: 5px;
}

.text-input {
  width: 80%;
  height: auto;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid lightgray;
}

::placeholder {
  font: 14px "Avenir", Helvetica, Arial, sans-serif;
}

.button {
  background-color: lightgray;
  color: white;
  padding: 10px 13px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profileImg {
  display: flex;
  border-radius: 50%;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Ensures the navbar spans the full width of the viewport */
  background-color: #32373b;
  overflow: hidden;
  padding: 20px;
}

.nav li {
  float: left;
  list-style-type: none;
}

.nav li a {
  color: white;
  padding: 14px 25px;
  text-decoration: none;
}

.nav li a:hover {
  background-color: #ddd;
  color: black;
  border-radius: 20px;
}

img {
  border-radius: 10px;
  margin: 10px;
}

.responsive {
  max-width: 600px;
  width: 70%;
  height: auto;
}

@font-face {
  font-family: "MyCustomFont";
  src: url("./fonts/MyCustomFont.otf") format("truetype");
}
.attention {
  background-color: #7d7d7d;
}

.title-screen {
  background-image: url("/public/images/seattle.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.a-header {
  font-size: 8em;
  font-family: "MyCustomFont", Arial, sans-serif;
  text-align: center;
  margin: 0;
}

.page {
  margin: 30px;
}

.cafe-section {
  display: flex;
  align-items: top;
  margin-bottom: 30px;
}

.cafe-details {
  max-width: 30%;
}

.cafe-name {
  font-size: 6em;
  font-family: "MyCustomFont", Arial, sans-serif;
  text-align: right;
  margin: 0;
  margin-top: 30px;
}

.location {
  font-size: 2em;
  font-family: Avenir, sans-serif;
  text-align: right;
  margin: 0;
}

.cafe-img {
  width: 70%;
  height: auto;
  margin-left: 20px;
  margin-right: 0;
  max-width: 900px;
}

.description {
  background-color: rgb(150, 150, 150);
  border-radius: 10px;
  padding: 20px;
  font-size: 1.2em;
  font-family: Avenir, sans-serif;
  margin: 10px 0 0 70px;
}
